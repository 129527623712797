import { Targets, TypedController } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller {
  @Targets
  declare readonly navigationItemTargets: HTMLAnchorElement[];

  connect() {
    this.focusOnFieldIfError();

    document
      .querySelector("#main-content")!
      .addEventListener("scroll", this.setupScrollHighlight);

    this.setupNavigationItemScroll();
    this.setupScrollHighlight();
  }

  private focusOnFieldIfError() {
    const errorMessages = document.getElementsByClassName(
      "field-error-message"
    );

    const firstErrorMessage = errorMessages[0];
    if (firstErrorMessage) {
      firstErrorMessage.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  private setupNavigationItemScroll() {
    this.navigationItemTargets.forEach((link) => {
      link.addEventListener("click", (e) => {
        const listItem = link.closest("li");
        if (listItem) {
          listItem.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "center",
          });
        }
      });
    });
  }

  private setupScrollHighlight = () => {
    const sections = ["personal", "address", "custom", "logindata"]
      .map((id) => document.getElementById(id))
      .filter((section): section is HTMLElement => section !== null);

    // Get scroll position and document height
    // const scrollPosition = window.scrollY + window.innerHeight;
    // const documentHeight = document.documentElement.scrollHeight;

    // Find current visible section
    let currentSections = sections.filter((section) => {
      const rect = section.getBoundingClientRect();
      return rect.top < window.innerHeight / 2;
    });

    let currentSection = currentSections.at(-1);

    // If no section is found and near the bottom, use last section
    if (!currentSection) {
      currentSection = sections[0];
    }

    if (currentSection) {
      this.navigationItemTargets.forEach((item) => {
        const fullUrl = new URL(window.location.href);
        fullUrl.hash = item.getAttribute("href")!;

        if (item.getAttribute("href") === `#${currentSection?.id}`) {
          item.classList.add("tw-font-bold");
          item.dataset.ui = "active";

          if (window.location.href !== fullUrl.href) {
            window.history.replaceState({}, "", fullUrl);

            // Add horizontal scroll for active navigation item
            const listItem = item.closest("li");
            if (listItem) {
              listItem.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "center",
              });
            }
          }
        } else {
          item.classList.remove("tw-font-bold");
          item.dataset.ui = "";
        }
      });
    }
  };

  disconnect() {
    window.removeEventListener("scroll", this.setupScrollHighlight);
  }
}
